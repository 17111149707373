@import '../config/functions';
@import '../config/colors';
.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    gap: 20px;
    &-bg {
        position: relative;
    }
    &__bg {
        position: absolute;
        z-index: 0;
        top: 0;
        right: -200px;
        height: 80%;
        transform: rotate(-90deg);
        &--second {
            height: 80%;
            left: -100px;
            transform: rotate(90deg);
            top: 70vh;
        }
    }
    @include media-breakpoint-down(lg) {
        justify-content: center;
    }
    &__content {
        flex: 1 1 481px;
        z-index: 2;
        &-title {
            color: color(font, title);
        }
    }
    &__image {
        border-radius: 1rem;
        display: flex;
        overflow: hidden;
        flex: 1 1 381px;
        width: 100%;
        z-index: 2;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: transform .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            &:hover {
                transform: scale(1.05);
            }
        }
    }
}