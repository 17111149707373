@import '../config/functions';
@import '../config/colors';
.navbar {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 19px;
    padding-bottom: 19px;
    a {
        display: flex;
        align-items: center;
    }
    &__logo, &-button {
        z-index: 4;
    }
    &-button {
        gap: 6px;
        flex-direction: column;
        justify-content: center;
        width: 30px;
        height: 18px;
        display: none;
        transition: .4s;
        span {
            transition: width .4s, transform .4s;
        }
        
        &.active {
            display: flex;
            &:hover {
                cursor: pointer;
            }
        }
        &.clicked {
            span {
                transform: translateY(-5px);
                &:nth-child(1) {
                    transform: rotate(45deg) translate(5px, 6px);
                }
                &:nth-child(2) {
                    width: 0px;
                    height: 0px;
                }
                &:nth-child(3) {
                    transform: rotate(-45deg) translate(4px, -4px);
                }
            }
        }
        span {
            width: 100%;
            height: 2px;
            background-color: #fff;
        }
    }    
    &__menu {
        display: flex;
        gap: 40px;
        @include media-breakpoint-down(md) {
            display: flex;
            gap: 12px;
            flex-direction: column;
            position: absolute;
            width: 100%;
            top: -100vh;
            left: 0;
            padding-left: 1rem;
            background-color: color(background, light-dark);
            padding-top: 92px;
            padding-bottom: 24px;
            height: auto;
            z-index: -1;
            transition: top .6s, z-index .8s;
            a {
                font-size: 20px;
                &::after {
                    width: auto;
                }
            }
            &.active {
                top: 0;
                z-index: 3;
            }
        }
    }
}