@for $i from 1 through 6 {
    h#{$i} {
        color: color(font, title);
    }
}

@font-face {
    font-family: 'Poppins';
    src: url('../../fonts/Poppins/Poppins-Regular.ttf') format(truetype);
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../../fonts/Poppins/Poppins-SemiBold.ttf') format(truetype);
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('../../fonts/Poppins/Poppins-Bold.ttf') format(truetype);
}

html, body {
    color: color(font, content);
    font-family: Poppins;
}

p, h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}

h2, h3, h4, h5, h6 {
    font-weight: 400;
    font-family: 'Poppins-SemiBold';
}

strong {
    font-weight: 700;
    font-family: 'Poppins-Bold';
}

h1 {
    font-size: 36px;
    line-height: 42px;
}

h2 {
    font-size: 32px;
    line-height: 38px;
}

h3 {
    font-size: 28px;
    line-height: 32px;
}

p {
    font-size: 18px;
    line-height: 22px;
}