@import '../config/functions';
@import '../config/colors';

.projects {
    margin-top: 120px;
    position: relative;
    .swiper {
        overflow: clip;
        overflow-x: visible;
    }
    &__list {
        &__card {
            $root: &;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            min-height: 320px;
            height: 100%;
            overflow: hidden;
            border-radius: 12px;
            gap: 12px;
            p {
                font-weight: 700;
                font-size: 20px;
                color: #fff;
            }
            &:hover {
                #{$root}--back {
                    opacity: 1;
                }
            }
            &--back {
                opacity: 0;
                position: absolute;
                top: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                background-color: color(background, light-dark-opacity);
                transition: opacity .4s cubic-bezier(0.165, 0.84, 0.44, 1);
                z-index: 2;
                p, a {
                    color: #fff;
                }
            }
            &-thumbnail {
                border-radius: 12px;
                object-fit: cover;
                width: 100%;
                height: 100%;
                min-height: 320px;
            }
            img {
                max-width: 100%;
                object-fit: cover;
            }
        }
    }
}