.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    p {
        display: flex;
        justify-content: center;
        gap: 6px;
        flex-wrap: wrap;
    }
}