@import 'config/main';
@import 'content/content';

html, body {
    background-color: color(background, dark); 
    box-sizing: border-box;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}
.mainteance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    font-family: Poppins;
    
    h1 {
        font-size: 4rem;
        line-height: 4.2rem;
        
    }
    h2 {
        font-size: 2.5rem;
        line-height: 2.7rem;
    }

    @include media-breakpoint-down(md) {
        h1 {
            font-size: 2rem;
            line-height: 2.2rem;
        }
        h2 {
            font-size: 1.4rem;
            line-height: 1.6rem;
        }
    }
}

a {
    color: color(font, link);
    text-decoration: none;
    transition: color .2s ease-in-out;
    position: relative;
    padding-bottom: 3px;
    overflow: hidden;
    &:hover {
        color: color(color, secondaryColor);
        &::after {
            width: 100%;
        }
    }
    &::after {
        position: absolute;
        display: flex;
        content: '';
        background-color: color(color, secondaryColor);
        width: 100%;
        height: 2px;
        bottom: 0;
        transform: translateX(-50%);
        left: 50%;
        width: 0px;
        transition: width .6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
}

.container {
    padding-left: 1rem;
    padding-right: 1rem;
}