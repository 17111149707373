@import '../config/functions';
@import '../config/colors';
.skills {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 82px;
    position: relative;
    &__list {
        display: flex;
        margin-bottom: 32px;
        min-height: 170px;
        &:hover {
            cursor: grab;
        }
        &__card {
            display: flex!important;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: color(background, light-dark);
            padding: 16px;
            border-radius: 12px;
            max-width: 170px;
            min-height: 170px;
            max-height: 170px;
            height: 100%;
            gap: 16px;
            &:hover {
                background: color(background, light-dark-hover);
            }
            img {
                max-width: 100%;
                max-height: 75px;
                object-fit: contain;
            }
            p {
                margin: 0px;
                font-weight: 600;
                color: color(background, light);
            }
        }
    }
    .swiper {
        overflow: clip;
        overflow-x: visible;
    }
    .swiper-wrapper {
        gap: 30px;
        @include media-breakpoint-down(md) {
            gap: 12px;
        }
    }
}