@import '../config/functions';
@import '../config/colors';

.experience {
    margin-top: 120px;
    position: relative;
    &__bg {
        position: absolute;
        height: 40vw;
        opacity: .2;
        right: -200px;
        z-index: 0;
        top: 100px;
    }
    &__table {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &__card {
            border-radius: 12px;
            border: 3px solid #393939;
            padding: 12px;
            $root: &;
            
            &.active {
                #{$root}-details {
                    max-height: 999px;
                    opacity: 1;
                    padding: 12px 0px;
                }
                #{$root}-head {
                    &::after {
                        transform: translateY(-50%) rotate(135deg);
                    }
                }
            }
            &-head {
                display: flex;
                justify-content: space-between;
                position: relative;
                padding-right: 36px;
                &:hover {
                    cursor: pointer;
                }
                p {
                    font-family: 'Poppins-SemiBold';
                    font-size: 18px;
                }
                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 32px;
                    color: color(color, secondaryColor);
                    right: 0;
                    transition: transform .4s;
                    content: '+';
                }
            }
            &-details {
                max-height: 0px;
                opacity: 0;
                overflow: hidden;
                transition: all .4s ease-in-out;
                border-top: 3px solid #393939;
                p {
                    display: flex;
                    align-items: center;
                    margin: 0;
                    gap: 20px;
                    img {
                        min-height: 28px;
                        max-height: 28px;
                    }
                }
                a {
                    &::after {
                        width: 0px;
                    }
                }
            }
        }
    }
}